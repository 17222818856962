import React from 'react'
import { Link, graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/seo'
import RightSide from '../components/rightside-general'

import BG from '../images/awards-bg.jpg'

class Careers extends React.Component {
	render() {
		const siteTitle = 'Careers'
		const siteDescription = this.props.data.site.siteMetadata.description

		return (
			<Layout location={this.props.location} title={siteTitle}>
				<SEO title={siteTitle} description={siteDescription} />
				<div
					id="sliderhead"
					style={{
						background: `url(${BG}) 50% 13px fixed rgb(34, 34, 34`,
						position: 'relative',
					}}
					className="blog-header2"
				/>
				<div className="container">
					<div className="row">
						<div className="col-md-8 left-list">
							<div id="contentgen">
								<h3>Careers</h3>
								<p>
									In WSC Group we pride ourselves on providing personalized
									professional services to our clients. We are a boutique
									accounting and business services firm which is continually
									growing and constantly on the lookout for quality employees.
								</p>
								<p>
									Our business is focused on securing strong results and we
									believe our approach to our clients’ accounting needs should
									be as unique as they are. Through understanding how our
									clients conduct their business, we can offer straightforward
									advice and guidance.
								</p>
								<p>
									WSC Group provides challenging opportunities and a rewarding
									career for all employees. Below are the standards which define
									WSC Group.
								</p>
								<p>
									<strong>Our Mission:</strong> Every day we inspire our clients
									to achieve greater success, wealth, financial security and
									work/life balance that without WSC Group would not be
									obtainable.
								</p>
								<p>
									<strong>Our Vision:</strong> Our Vision is to be regarded by
									our clients, our competitors, and our staff as being the firm
									of excellence in the accounting profession.
								</p>
								<p>
									<strong>Our Values:</strong> Our non-negotiable values are:
								</p>
								<ul>
									<li>Integrity with humility</li>
									<li>Learning through education</li>
									<li>Leadership through mentoring</li>
									<li>Accountability and responsibility</li>
								</ul>
								<p>
									If these principles sound appealing WSC Group would like to
									hear from you.
								</p>
								<p>
									To view our current vacancies please{' '}
									<Link to="/current-vacancies">Click Here</Link>.
								</p>
								<p>
									If you would like your details kept on record for future
									opportunities, please forward to&nbsp;
									<a href="mailto:recruitment@wscgroup.com.au">
										recruitment@wscgroup.com.au
									</a>
									.
								</p>
							</div>
						</div>
						<div className="col-md-4 right-list pull-right">
							<RightSide />
						</div>
					</div>
				</div>
			</Layout>
		)
	}
}

export default Careers

export const CareersPageQuery = graphql`
	query CareersPageQuery {
		site {
			siteMetadata {
				title
				author
				description
			}
		}
	}
`
